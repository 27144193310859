<template>
  <div :class="['page',{'page--layout-sidebar' : layout === 'sidebar'}]">
		<slot></slot>
	</div>
</template>

<script>
export default {
  props: {
    layout: String,
	},
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';
.page{
	background: var(--page-background);
	width: var(--page-width);
	min-height: var(--page-min-height);
	box-shadow: var(--page-box-shadow);
	padding: var(--page-padding);
}
.page--layout-sidebar{
	@include breakpoint(md){
		display: grid;
		grid-template-columns: var(--page-with-sidebar-columns);
		grid-template-rows: var(--page-with-sidebar-rows);
	}
}
</style>

<!-- Used in : AR, DS -->